import { Box } from '@mui/material';

import useHotelService from './hotel-ser-hooks';
import { HeaderLayout, Layout } from '../InRoomDining';
import { FullScreenLoader, ServiceCard } from '../../components';
import { useParams } from 'react-router-dom';

const Services = () => {
  const { hotelService, loading } = useHotelService()
  const {id}=useParams()  
  

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <Layout>
      <HeaderLayout title="Hotel Services" />
      <Box
        sx={{
          p: 2,
          pt: 0,
          flex: 1,
          overflow: 'auto',
          background: '#F4F4F4',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {Array.isArray(hotelService) &&
          hotelService?.find((service)=>service.id===id)?.sub_services?.map((hotelService: any) => {
            
            return (
              <ServiceCard
                showInfo={true}
                key={hotelService?.name}
                label={hotelService?.name}
                description={hotelService?.desc}
                image={hotelService?.img as string}
                serviceID={hotelService?.service_id}
                booking={hotelService?.booking_url}

              />
            );
          })}
      </Box>
    </Layout>
  );
};

export default Services;
