import { Box, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../store";
import { ChatIcon } from "../../../components";
import { imageFallback } from "../../../utils";
import useHotelInfo from "../../HotelInfo/hotel-info-hooks";

const Header = () => {
  const guest = useAuth((state: any) => state.guest);
  const navigate = useNavigate();
  const guestName = guest?.guest_name || "";
  const {propertyDetails} = useHotelInfo();

  return (
    <Box
      sx={{
        px: 2,
        display: "flex",
        fontWeight: 600,
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: (theme) => theme.spacing(2.5),
      }}
    >
      <Box>
        <Box>
          Hello
          <Box
            width={36}
            height={36}
            component="img"
            alt="Hello Emoji"
            onError={imageFallback}
            src="/assets/hand-emoji.png"
            style={{ verticalAlign: "bottom" }}
          />
        </Box>
        <Box>{guestName}</Box>
      </Box>

      {propertyDetails?.getPropertyByDomain?.show_talk_to_us && <Chip
        variant="filled"
        color="primary"
        label="Talk to us"
        deleteIcon={<ChatIcon />}
        onClick={() => navigate("/chat")}
        onDelete={() => navigate("/chat")}
        sx={{ fontSize: (theme) => theme.spacing(1.75) }}
      />
    }
    </Box>
  );
};

export { Header };
