import { Box } from "@mui/material";

import useHotelRooms from "./hotel-rooms-hooks";
import { HeaderLayout, Layout } from "../InRoomDining";
import { FullScreenLoader, ServiceCard } from "../../components";

const HotelRooms = () => {
  const { loading, hotelRooms } = useHotelRooms();

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <Layout>
      <HeaderLayout title="Hotel Rooms" />
      <Box
        sx={{
          p: 2,
          pt: 0,
          flex: 1,
          overflow: "auto",
          background: "#F4F4F4",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        {Array.isArray(hotelRooms) &&
          hotelRooms.map((hotelRoom: any) => {
            return (
              <ServiceCard
                showInfo={true}
                key={hotelRoom?.room_type}
                label={hotelRoom?.room_type}
                description={hotelRoom?.desc}
                image={hotelRoom?.img as string}
              />
            );
          })}
      </Box>
    </Layout>
  );
};

export default HotelRooms;
