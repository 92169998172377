import { Close } from '@mui/icons-material';
import {
  Dialog,
  DialogTitle,
  Box,
  Typography,
  IconButton,
  DialogContent,
  Tooltip
} from '@mui/material';
import * as React from 'react';
import { CHILLI_ICONS } from '../../constant/services';
import { imageFallback } from '../../utils';
import { useMenuItem } from '../../view/InRoomDining/room-dining-hook';

interface props {
  open: boolean;
  title: string;
  desc: string;
  image: string;
  price: number;
  icon: string;
  currency: string;
  allergens: string[];
  handleDialogClose: () => void;
  is_recommended: boolean;
  spice_level: string;
}
const DineInDialog = ({
  open,
  handleDialogClose,
  title,
  desc,
  image,
  price,
  icon,
  currency,
  allergens,
  is_recommended,
  spice_level
}: props) => {
  const { ALLERGEN_NAMES } = useMenuItem();
  const chili = (CHILLI_ICONS as any)[spice_level];
  return (
    <>
      <Dialog
        open={open}
        onClose={handleDialogClose}
        sx={{
          '.MuiDialog-paper': {
            width: '316px'
          }
        }}
      >
        <DialogTitle
          color={'#fff'}
          bgcolor={theme => theme.palette.primary.main}
        >
          <Box
            display={'flex'}
            alignItems="center"
            justifyContent={'space-between'}
          >
            <Typography variant="h6">{title}</Typography>
            <IconButton color={'inherit'} onClick={handleDialogClose}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        {image ? (
          <Box style={{ padding: '10px' }}>
            <img
              alt=""
              src={image}
              style={{
                objectFit: 'contain',
                borderRadius: '10px',
                width: '100%',
                height: '220px'
              }}
            />
          </Box>
        ) : (
          ''
        )}

        <DialogContent sx={{ flex: 1, p: 1.5 }}>
          <Box sx={{ display: 'flex' }}>
            <Typography variant="body2" sx={{ fontWeight: 600 }}>
              {title}
            </Typography>
            {is_recommended === true && (
              <Tooltip enterTouchDelay={0} title="Chef's Recommendation">
                <Box
                  component="img"
                  onError={imageFallback}
                  style={{
                    height: '20px',
                    width: '20x',
                    marginLeft: '5px',
                  }}
                  src={`/assets/chef.svg`}
                />
              </Tooltip>
            )}
          </Box>
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            {currency} {price}
          </Typography>
          {icon === 'Vegan' && (
            <Typography
              sx={{
                color: 'green'
              }}
            >
              Vegan
            </Typography>
          )}
          {icon === 'NonVegan' && (
            <Typography
              sx={{
                color: 'red'
              }}
            >
              NonVegan
            </Typography>
          )}
          {allergens?.length !== 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              <Typography>Allergens :&nbsp;</Typography>
              {allergens?.map((all, index) => {
                const allergenName = (ALLERGEN_NAMES as any)[all];
                return (
                  <Tooltip enterTouchDelay={0} title={allergenName}>
                    {index === allergens?.length - 1 ? (
                      <Typography sx={{ pr: 1 }}>{allergenName}</Typography>
                    ) : (
                      <Typography sx={{ pr: 1 }}>{allergenName},</Typography>
                    )}
                  </Tooltip>
                );
              })}
            </Box>
          )}
          <Typography>{chili}</Typography>
          <Typography variant="body2" sx={{ mt: 0.5 }}>
            {desc}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DineInDialog;
