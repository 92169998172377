import * as React from "react";
import SendIcon from "@mui/icons-material/Send";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import { Box, IconButton, InputBase, SxProps, Theme } from "@mui/material";
// import AttachFileIcon from "@mui/icons-material/AttachFile";

import { useAuth, useChat } from "../../store";
import { FullScreenLoader } from "../../components";
import {
  useHotelDetailsQuery,
  usePropertyDetailsQuery,
} from "../../generated/graphql";
import { CustomButton, HeaderLayout, Layout } from "../InRoomDining";
import { scrollToBottom, useDomain } from "../../utils";
import { useCallback } from "react";

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

enum MessageType {
  Register = "register",
  Message = "message",
  Response = "agent_bot_msg",
}

const useWebSocket = (onMessageReceived: Function) => {
  const [isReady, setIsReady] = React.useState(false);
  const ws = React.useRef<any>(null);
  const guest = useAuth((state: any) => state.guest);

  const domainId = useDomain();

  const { data: propertyDetails} = usePropertyDetailsQuery({
    variables: {
      domain: domainId as string,
    },
  });

  React.useEffect(() => {
    if (propertyDetails?.getPropertyByDomain) {
      const socket = new WebSocket(
        propertyDetails?.getPropertyByDomain?.chat_api_url || ""
      );

      socket.onerror = (event) => {
        console.log("Error:", event);
      };

      socket.onopen = () => {
        ws.current?.send(
          JSON.stringify({
            type: MessageType.Register.toString(),
            guest_name: guest?.guest_name,
            room_number: guest?.room_id,
            guest_id: guest?.guest_id,
            pass: "pass",
          })
        );
        setIsReady(true);
      };
      socket.onclose = () => setIsReady(false);
      socket.onmessage = (event) => onMessageReceived(event.data);

      ws.current = socket;

      return () => {
        socket.close();
      };
    }
    // eslint-disable-next-line
  }, [propertyDetails,guest?.guest_name,guest?.room_id,guest?.guest_id]);
  // bind is needed to make sure `send` references correct `this`
  return [isReady, ws.current?.send.bind(ws.current)];
};

const Footer = ({ send }: any) => {
  const [chatInput, setChatInput] = React.useState("");

  const sendMessage = () => {
    if (chatInput.trim().length === 0) return;

    send(chatInput);
    setChatInput("");
  };

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          borderRadius: "15px",
          backgroundColor: "background.grey",
        }}
      >
        <InputBase
          // rows={2}
          // multiline
          value={chatInput}
          sx={{ m: 1.25, ml: 1, flex: 1 }}
          placeholder="Type here…"
          onChange={(e) => setChatInput(e.target.value)}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              sendMessage();
            }
          }}
        />
        {/* <IconButton color="primary" sx={{ p: 1 }}>
          <AttachFileIcon />
        </IconButton> */}
        <IconButton color="primary" sx={{ p: 1 }} onClick={sendMessage}>
          <SendIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

interface MessageProps {
  text: string;
  sx?: SxProps<Theme>;
}

const Message = ({ sx, text }: MessageProps) => {
  return (
    <Box
      sx={{
        p: 1.5,
        width: "70%",
        ...(sx ? sx : {}),
      }}
    >
      {text}
    </Box>
  );
};

function Chat() {
  const chatViewRef = React.useRef<any>();
  const guest = useAuth((state: any) => state.guest);
  const { data, loading } = useHotelDetailsQuery({
    variables: {
      propertyID: guest?.property_id,
    },
  });
  const chat = useChat((state: any) => state.chat);
  const addToChat = useChat((state: any) => state.addToChat);

  const onMessageReceived = useCallback((text: any) => {
    console.log("Recieved:", text);
    addToChat({ type: MessageType.Response, message: JSON.parse(text).msg });
  },[addToChat]);

  const [isReady, send] = useWebSocket(onMessageReceived);

  const onMessageSend = (text: string) => {
    const message = { type: MessageType.Message, message: text };
    send(
      JSON.stringify({
        type: MessageType.Message.toString(),
        guest_name: guest?.guest_name,
        room_number: guest?.room_id,
        guest_id: guest?.guest_id,
        pass: "pass",
        msg: text,
      })
    );
    addToChat(message);
  };

  React.useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      if (!chatViewRef.current) return;
      scrollToBottom(chatViewRef.current);
    }, 500);

    return () => window.clearTimeout(timeoutID);
  }, [chat]);

  if (!isReady || loading) return <FullScreenLoader />;

  const phoneNumber = data?.getMeta?.dir_con?.phone;

  return (
    <Layout>
      <HeaderLayout title="Talk to Us">
        <CustomButton
          variant="contained"
          onClick={() => phoneNumber && window.open(`tel:${phoneNumber}`)}
          startIcon={<AddIcCallIcon sx={{ fontSize: "inherit !important" }} />}
        >
          Call
        </CustomButton>
      </HeaderLayout>

      <Box
        sx={{
          p: 2,
          flex: 1,
          display: "flex",
          overflow: "auto",
          flexDirection: "column",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          backgroundColor: "background.light",
          "&::-webkit-scrollbar": { display: "none" },
        }}
        ref={(ref) => {
          chatViewRef.current = ref;
          chatViewRef.current && scrollToBottom(chatViewRef.current);
        }}
      >
        {chat.map((message: any, index: number) => {
          const { message: text, type } = message;
          const leftBorderRadius = "0px 16px 16px 16px";
          const rightBorderRadius = "15px 0px 15px 15px";
          const isSentMessage = type === MessageType.Message;

          return (
            <Message
              key={index}
              text={text}
              sx={{
                mt: index !== 0 ? 2 : 0,
                borderRadius: isSentMessage
                  ? rightBorderRadius
                  : leftBorderRadius,
                alignSelf: isSentMessage ? "end" : "auto",
                backgroundColor: isSentMessage
                  ? "primary.light"
                  : "background.grey",
              }}
            />
          );
        })}
      </Box>

      <Footer send={onMessageSend} />
    </Layout>
  );
}

export default Chat;
