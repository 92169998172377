import { Box } from "@mui/material";

import useDining from "./dining-hooks";
import { HeaderLayout, Layout } from "../InRoomDining";
import { FullScreenLoader, ServiceCard } from "../../components";

const DiningMenu = () => {
  const { dining, loading } = useDining();

  if (loading) {
    return <FullScreenLoader />;
  }

  return (
    <Layout>
      <HeaderLayout title="Dining" />
      <Box
        sx={{
          p: 2,
          pt: 0,
          flex: 1,
          overflow: "auto",
          background: "#F4F4F4",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        {Array.isArray(dining) &&
          dining.map((service) => {
            return (
              <ServiceCard
                showInfo={true}
                key={service?.name}
                label={service?.name}
                description={service?.desc}
                image={service?.img as string}
                serviceID={service?.service_id}
              />
            );
          })}
      </Box>
    </Layout>
  );
};

export default DiningMenu;
