import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  SwipeableDrawer,
  Typography
} from '@mui/material';
import { usePropertyServicesMutationMutation } from '../../generated/graphql';
import { useAuth } from '../../store';

import { imageFallback, useServiceAlert } from '../../utils';
import useInfo from '../../view/Dashboard/components/InfoCard';

type DrawerProps = {
  name: string;
  desc: string;
  image: string;
  open: boolean;
  serviceID?: number | null;
  handleOpen: () => void;
  handleClose: () => void;
  bookingUrl:string
};

function Drawer(props: DrawerProps) {
  const { name, open, desc, image, serviceID, handleOpen, handleClose,bookingUrl } = props;
  const [requestService] = usePropertyServicesMutationMutation();
  const { background_color } = useInfo();
  const guest = useAuth((state: any) => state.guest);
  const serviceAlert = useServiceAlert(() => {});
  const clickHandler = async () => {
    if (!serviceID) {
      return;
    }
    try {
      const res = await requestService({
        variables: {
          input: {
            quantity: 1,
            property_id: guest?.property_id,
            room_id: guest?.room_id,
            task_id: serviceID
          }
        }
      });
      if (res.data?.requestService?.code === '200') {
        serviceAlert('Submitted Successfully');
        handleClose();
      } else {
        serviceAlert(
          res.data?.requestService?.message ||
            'Something went wrong! Please contact support'
        );
        handleClose();
      }

      // Success alert
    } catch (error) {
      serviceAlert(`Something went wrong! Please try again`);
      handleClose();
    }
  };

  return (
    <SwipeableDrawer
      open={open}
      anchor="bottom"
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Box
        sx={{
          pt: 0.5,
          pr: 3,
          pl: 3,
          overflow: 'auto',
          maxHeight: '60vh',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        <Box style={{float:"right"}}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>

        {image ? (
          <Box
            alt={name}
            src={image}
            component="img"
            onError={imageFallback}
            sx={{ height: 250, width: '100%', objectFit: 'contain' }}
          />
        ) : (
          <Box
            alt={name}
            src="/assets/no.jpg"
            component="img"
            sx={{
              height: 250,
              width: 200,
              marginLeft: '45%',
              objectFit: 'cover'
            }}
          />
        )}

        <Typography variant="h6" sx={{ fontSize: theme => theme.spacing(2.5) }}>
          {name}
        </Typography>
        {
          desc?.toString().startsWith("<") ? (
            <div
              contentEditable="false"
              dangerouslySetInnerHTML={{ __html: desc?.toString() || '' }}
            ></div>
          ) : (
            <Typography sx={{ color: 'text.secondary' }}>
              {desc}
            </Typography>
          )
        }
        {serviceID && guest?.room_id && (
          <Button
            onClick={clickHandler}
            sx={{ backgroundColor: background_color, color: 'white', mt: 1 }}
          >
            {name.trim() === 'Late Check-out'
              ? 'Request for Late Check-out'
              : 'Request for Booking'}
          </Button>
        )}
        {bookingUrl &&<Button target="_blank" rel="noopener noreferrer" href={bookingUrl}
            sx={{ backgroundColor: background_color, color: 'white', mt: 1 }}>Book Now</Button> }
      </Box>
    </SwipeableDrawer>
  );
}

export { Drawer };
