import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, Link, Typography } from "@mui/material";

import {
  usePropertyDetailsQuery,
  useHotelDetailsLazyQuery,
} from "../../../generated/graphql";
import { CardPaper } from "./CardPaper";
import { imageFallback, useDomain } from "../../../utils";

const useInfo = () => {
  const [hotelDetailsQuery, { data: propertyMeta, loading: loadingMeta }] =
    useHotelDetailsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails, loading: loadingProperty } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string,
      },
    });

  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    hotelDetailsQuery({
      variables: {
        propertyID,
      },
    });
  }, [propertyDetails?.getPropertyByDomain?.id,hotelDetailsQuery]);

  const descriptionString = propertyMeta?.getMeta?.about?.desc;
  const imgUrl = propertyDetails?.getPropertyByDomain?.img;
  const currency=propertyDetails?.getPropertyByDomain?.currency;
  const layout=propertyDetails?.getPropertyByDomain?.layout
  const background_color=propertyDetails?.getPropertyByDomain?.background_color

  return {
    background_color,
    currency,
    imgUrl,
    descriptionString,
    loading: loadingMeta || loadingProperty,
    layout
  };
};
 export default useInfo;
const InfoCard = () => {
  const navigate = useNavigate();
  const { imgUrl, loading, descriptionString } = useInfo();
  // const [showMore, setShowMore] = React.useState(false);
  // const toggleShowMore = () => {
  //   setShowMore(!showMore);
  // };
  const getDescription = () => {
    const words = descriptionString?.split(" ");
    return `${words?.slice(0, 20).join(" ")}...`;
    // if (showMore) {
    //   return descriptionString;
    // } else {
    //   const words = descriptionString?.split(" ");
    //   const wordLength = words?.length || 0;
    //   if (wordLength > 20) {
    //     return `${words?.slice(0, 20).join(" ")}...`;
    //   } else {
    //     return descriptionString;
    //   }
    // }
  };

  if (loading) {
    return (
      <Box sx={{ my: 4, display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <CardPaper sx={{ mt: 1, px: 1, py: 1 }}>
      <Box sx={{ mb: 1, textAlign: "center" }}>
        <Box
          height={100}
          src={imgUrl}
          alt="App Logo"
          component="img"
          onError={imageFallback}
        />
      </Box>

      {descriptionString && (
        <>
        {descriptionString.toString().startsWith("<") ? (
        <div
          contentEditable="false"
          dangerouslySetInnerHTML={{ __html: getDescription() || '' }}
        ></div>
      ) : (
        <Typography sx={{ color: 'text.secondary' }} variant="body2">
          {getDescription()}
        </Typography>
      )}
        {/* <Typography variant="body2" sx={{ color: "text.secondary" }}>
          <div dangerouslySetInnerHTML={{ __html: getDescription() || "" }} style={{margin:'5px'}}></div>
        </Typography> */}
        <Link
        underline="none"
        onClick={() => navigate("/hotel-information")}
        sx={{ ml: 0.5, fontSize: "inherit", fontWeight: 600 }}
      >
        Read More..
      </Link>
      </>
      )}
    </CardPaper>
  );
};

export { InfoCard };