import create from 'zustand';
import { persist } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

const SERVICE_REQUEST = 'SERVICE_REQUEST';

let store: any = (set: any) => ({
  services: {},
  addToService: (id: string) =>
    set((state: any) => {
      const currentItem = state.services[id];
      const isPresent = Boolean(currentItem);

      if (!isPresent) {
        state.services[id] = { id, count: 1 };
      } else {
        currentItem.count += 1;
      }
    }),
  removeFromService: (id: string) =>
    set((state: any) => {
      const currentItem = state.services[id];
      const isPresent = Boolean(currentItem);

      if (!isPresent) return;

      currentItem.count = Math.max(currentItem.count - 1, 0);
    }),
  cancelServices: () =>
    set((state: any) => {
      return {
        ...state,
        services: {}
      };
    })
});

store = immer(store);
store = persist(store, {
  name: SERVICE_REQUEST
});

const useServices = create(store);

export { useServices };
