import React from 'react';
import Card from '@mui/material/Card';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CardMedia from '@mui/material/CardMedia';

import { Drawer } from '../Drawer';

interface CommonProps {
  image: string;
  label?: string;
  serviceID?: number | null;
  children?: React.ReactNode;
  booking?:string
}

type ConditionalProps =
  | {
      path: string;
      showInfo: false;
      description?: never;
    }
  | {
      path?: never;
      showInfo: true;
      description?: string;
    };

type DashboardProps = CommonProps & ConditionalProps;

const ServiceCard = (props: DashboardProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [imageLoading, setImageLoading] = React.useState(true);
  const { image, label, path, children, showInfo, description, serviceID ,booking} = props;

  const handleOpen = () => {
    if (showInfo) setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card
        sx={{
          mt: 2,
          borderRadius: 0,
          boxShadow: 'none',
          ...(label ? { position: 'relative' } : {})
        }}
        onClick={handleOpen}
      >
        {imageLoading && (
          <Box
            sx={{
              height: 250,
              width: '100%',
              display: 'flex',
              position: 'absolute',
              alignItems: 'center',
              backgroundColor: 'white',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {image ? (
          <CardMedia
            alt={label}
            image={image}
            component="img"
            sx={{ height: 250 }}
            onLoad={() => setImageLoading(false)}
            onClick={showInfo ? () => handleOpen() : () => navigate(path)}
          />
        ) : (
          <Box>
            <CardMedia
              alt={label}
              image="/assets/no.jpg"
              component="img"
              sx={{ height: 250, width: 200, marginLeft: '45%' }}
              onLoad={() => setImageLoading(false)}
              onClick={showInfo ? () => handleOpen() : () => navigate(path)}
            />
          </Box>
        )}

        {label && (
          <Typography
            variant="h6"
            sx={{
              ...(image ? { color: '#fff' } : { color: 'black' }),
              position: 'absolute',
              left: theme => theme.spacing(2),
              bottom: theme => theme.spacing(1),
              fontSize: theme => theme.spacing(2.5)
            }}
          >
            {label}
          </Typography>
        )}

        {/* Extra stuff to render */}
        {children}
      </Card>
      {showInfo && (
        <Drawer
          open={open}
          image={image}
          name={label as string}
          handleOpen={handleOpen}
          handleClose={handleClose}
          desc={description as string}
          serviceID={serviceID}
          bookingUrl={booking||''}
        />
      )}
    </>
  );
};

export { ServiceCard };
