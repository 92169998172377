import { Box, Chip } from "@mui/material";
import { imageFallback} from "../../utils";
import { FeedbackAndPromotions, InfoCard } from "./components";
import { ChatIcon } from "../../components";
import { useNavigate } from "react-router-dom";

function NotVerified() {
  const navigate=useNavigate()
  return (
    <Box
      sx={{
        p: 2,
        flex: 1,
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": { display: "none" },
      }}
    >
      <Box
        sx={{
          px: 2,
          display: "flex",
          fontWeight: 600,
          alignItems: "center",
          justifyContent: "center",
          fontSize: (theme) => theme.spacing(2.5),
        }}
      >
        Hello
        <Box
          width={36}
          height={36}
          alt="Emoji"
          component="img"
          onError={imageFallback}
          src="/assets/hand-emoji.png"
          sx={{ ml: 0.5, verticalAlign: "bottom" }}
        />
          <Chip
        variant="filled"
        color="primary"
        label="Talk to us"
        deleteIcon={<ChatIcon />}
        onClick={() => navigate("/chat")}
        onDelete={() => navigate("/chat")}
        sx={{ fontSize: (theme) => theme.spacing(1.75) }}
      />
      </Box>

      <InfoCard />

      <FeedbackAndPromotions hideFeedback={true} />
    </Box>
  );
}

export default NotVerified;
