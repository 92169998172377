import {
  Box,
  Button,
  Checkbox,
  Typography,
  SwipeableDrawer,
  FormControlLabel,
  Divider,
  Snackbar,
  Slide,
  SlideProps
} from '@mui/material';
import { cloneDeep, isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { useCart } from '../../store';
import { DineInItem, Selection } from '../../generated/graphql';
import useInfo from '../../view/Dashboard/components/InfoCard';

interface ExtraItemDrawerProps {
  open: boolean;
  currentItem: DineInItem;
  toggleDrawer: () => void;

  extraItems?: any;
  selections?: Selection[];
  buttonText?: string;
  updateCartItemExtra?: any;
}

type TransitionProps = Omit<SlideProps, 'direction'>;
function ExtraItemDrawer({
  open,
  currentItem,
  toggleDrawer,

  buttonText,
  extraItems,
  selections,
  updateCartItemExtra
}: ExtraItemDrawerProps) {
  const { currency } = useInfo();
  const { control, register, getValues, setValue, reset } = useForm();
  const { remove } = useFieldArray({ control, name: 'extras' });
  const addToCartStore = useCart((state: any) => state.addToCart);
  const [cartSelections, setCartSelections] = useState<Selection[]>();
  const [checkedObj, setCheckedObj] = useState<{ [key: string]: boolean }>({});
  const [snackbar, setSnackBar] = useState(false);
  const [msg, setMsg] = useState('');

  useEffect(() => {
    remove();
  }, [currentItem, remove]);

  useEffect(() => {
    // updates snackbar state after certain time
    if (!snackbar) {
      return;
    }
    const timer = setTimeout(() => {
      setSnackBar(false);
      setMsg('');
    }, 2500);

    return () => clearTimeout(timer);
  }, [snackbar]);

  //   Updates value

  useEffect(() => {
    if (!selections || !currentItem) return;
    setCartSelections(selections);
  }, [currentItem, selections]);

  useEffect(() => {
    if (!cartSelections) return;
    let itemIdObj: { [key: string]: boolean } = {};
    cartSelections.forEach(selection =>
      selection.selection_items.forEach(
        selectionItem => (itemIdObj[selectionItem!.id] = true)
      )
    );
    setCheckedObj(itemIdObj);
  }, [cartSelections]);

  useEffect(() => {
    if (!extraItems || !currentItem) return;
    const indexArrForCheck = extraItems.map((item: any) =>
      currentItem.extraOptions?.findIndex(
        extraOption => extraOption?.name === item.name
      )
    );
    indexArrForCheck.forEach((item: number) => {
      setValue(`extras.${item}.checked`, true);
    });
  }, [setValue, extraItems, currentItem]);

  const addToCart = () => {
    const extraItems = getValues()
      .extras.filter(({ checked }: any) => Boolean(checked))
      .map(({ checked, ...rest }: any) => ({ ...rest }));

    let cleanedSelections;
    if (cartSelections) {
      cleanedSelections = JSON.parse(
        JSON.stringify(cartSelections, (name, val) => {
          if (name === '__typename') {
            delete val[name];
          } else {
            return val;
          }
        })
      );
    }

    if (!updateCartItemExtra) {
      if (isEmpty(extraItems) && isEmpty(cleanedSelections)) {
        addToCartStore(currentItem.id);
      } else {
        addToCartStore(currentItem.id, extraItems, cleanedSelections);
      }
    } else {
      updateCartItemExtra(extraItems, cleanedSelections);
    }

    toggleDrawer();
  };

  function handleChange(
    selectionIndex: number,
    selectionItemIndex: number,
    checked: boolean
  ): void {
    if (!currentItem.selections) {
      return;
    }
    if (checked) {
      if (cartSelections) {
        const copy = cloneDeep(cartSelections);
        const index = (copy || []).findIndex(
          item =>
            item.id === (currentItem?.selections || [])[selectionIndex]?.id
        );
        if (index !== -1) {
          const limit = copy[index].limit || Infinity;
          const lengthToBe = copy[index].selection_items.length + 1;
          if (lengthToBe > limit) {
            setMsg(`You can add ${limit} choices max`);
            setSnackBar(true);
            return;
          }
          copy[index].selection_items.push(
            currentItem?.selections[selectionIndex]!.selection_items[
              selectionItemIndex
            ]
          );
          setCartSelections(copy);
        } else {
          const selection = cloneDeep(currentItem.selections[selectionIndex]);
          const selectionItem =
            currentItem.selections[selectionIndex]?.selection_items[
              selectionItemIndex
            ];
          selection!.selection_items = [selectionItem!];
          setCartSelections([...cartSelections, selection!]);
        }
      } else {
        const selection = cloneDeep(currentItem.selections[selectionIndex]);
        const selectionItem =
          currentItem.selections[selectionIndex]?.selection_items[
            selectionItemIndex
          ];
        selection!.selection_items = [selectionItem!];
        setCartSelections([selection!]);
      }
    } else {
      if (!cartSelections) {
        return;
      }
      const copy = cloneDeep(cartSelections);
      const index = (copy || []).findIndex(
        item => item.id === (currentItem?.selections || [])[selectionIndex]?.id
      );
      if (copy[index].selection_items.length <= 1) {
        copy.splice(index, 1);
      } else {
        const siIndex = copy[index].selection_items.findIndex(
          item =>
            item?.id ===
            (currentItem?.selections || [])[selectionIndex]?.selection_items[
              selectionIndex
            ]
        );
        copy[index].selection_items.splice(siIndex, 1);
      }
      setCartSelections(copy);
    }
  }

  const onCloseDialog = () => {
    reset();
    toggleDrawer();
  };

  return (
    <SwipeableDrawer
      open={open}
      anchor="bottom"
      onOpen={toggleDrawer}
      onClose={onCloseDialog}
    >
      <Box
        sx={{
          p: 2,
          overflow: 'auto',
          maxHeight: '100vh',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {currentItem?.selections && (
          <Typography fontSize={17} fontWeight={800}>
            Selections
          </Typography>
        )}
        {currentItem?.selections &&
          currentItem?.selections.map((selection, index) => (
            <Box
              key={selection?.id}
              // sx={{
              //   mb: 1,
              //   display: 'flex',
              //   alignItems: 'center',
              //   textTransform: 'capitalize',
              //   justifyContent: 'space-between'
              // }}
            >
              <input
                type="hidden"
                value={selection?.id}
                {...register(`selections.${index}.id`)}
              />

              <Typography>{selection?.name}</Typography>
              {selection?.limit !== 0 && (
                <Typography fontSize={12} color={'grey'} fontFamily={'cursive'} fontWeight={600}>
                  (Choose {selection?.limit})
                </Typography>
              )}
              {selection?.selection_items?.map((selectionItem, nestedIndex) => (
                <Box
                  key={selectionItem?.id}
                  sx={{
                    mb: -1.5,
                    display: 'flex',
                    alignItems: 'center',
                    textTransform: 'capitalize',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography variant="body2" sx={{ flex: 3 }}>
                    {selectionItem?.name}
                  </Typography>
                  {selectionItem?.price !== 0 && (
                    <Typography
                      variant="body2"
                      sx={{ flex: 1, textAlign: 'center' }}
                    >
                      {currency} {selectionItem?.price}
                    </Typography>
                  )}
                  <Box sx={{ flex: 1, textAlign: 'right' }}>
                    <Checkbox
                      checked={checkedObj[selectionItem?.id || ''] || false}
                      onChange={e =>
                        handleChange(index, nestedIndex, e.target.checked)
                      }
                    />
                  </Box>
                </Box>
              ))}
              <Divider />
            </Box>
          ))}
        {Boolean(
          currentItem?.extraOptions && currentItem?.extraOptions.length !== 0
        ) && (
          <Typography fontSize={15} fontWeight={800} mt={2}>
            Extras
          </Typography>
        )}
        {Boolean(currentItem?.extraOptions) &&
          currentItem.extraOptions?.map((extraItem: any, index: number) => (
            <Box
              key={index}
              sx={{
                mb: 1,
                display: 'flex',
                alignItems: 'center',
                textTransform: 'capitalize',
                justifyContent: 'space-between'
              }}
            >
              <input
                type="hidden"
                value={extraItem.name}
                {...register(`extras.${index}.name`)}
              />
              <input
                type="number"
                value={extraItem.price}
                style={{ display: 'none' }}
                {...register(`extras.${index}.price`)}
              />
              <Typography variant="body2" sx={{ flex: 3 }}>
                {extraItem.name}
              </Typography>
              <Typography variant="body2" sx={{ flex: 1, textAlign: 'center' }}>
                {currency} {extraItem.price}
              </Typography>
              <Box sx={{ flex: 1, textAlign: 'right' }}>
                {/* <Checkbox {...register(`extras.${index}.checked`)} /> */}

                <FormControlLabel
                  sx={{ mr: 0 }}
                  control={
                    <Controller
                      control={control}
                      defaultValue={false}
                      name={`extras.${index}.checked`}
                      render={({ field: { value, onChange } }) => (
                        <Checkbox
                          checked={value}
                          onChange={e => onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                  label={false}
                />
              </Box>
            </Box>
          ))}
      </Box>
      {/* Drawer Footer */}
      <Box sx={{ p: 1 }}>
        <Button fullWidth variant="contained" onClick={() => addToCart()}>
          {buttonText || 'Add to cart'}
        </Button>
      </Box>
      <Snackbar
        open={snackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        TransitionComponent={(props: TransitionProps) => (
          <Slide {...props} direction="down" />
        )}
        message={msg}
      />
    </SwipeableDrawer>
  );
}

export { ExtraItemDrawer };
