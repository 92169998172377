import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { camelCase, startCase, snakeCase, flatten, groupBy } from 'lodash';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  SxProps,
  Theme,
  Typography
} from '@mui/material';

import {
  Header,
  Slider,
  InfoCard,
  SliderCard,
  FeedbackAndPromotions,
  ServiceAndOrder,
  QuickServices
} from './components';
import { useAuth } from '../../store';
import { useMenuItem } from '../InRoomDining/room-dining-hook';
import {
  usePropertyServicesQuery,
  Layout,
  useGetGuestPromotionsLazyQuery,
  usePropertyDetailsQuery
} from '../../generated/graphql';
import {
  SERVICES_ICONS,
  IN_ROOM_DINING_ICONS,
  IN_ROOM_CATEGORY_ICONS,
  SERVICEINFO_ICONS
} from '../../constant/services';
import useInfo from './components/InfoCard';
import useHotelService from '../HotelSer/hotel-ser-hooks';
import { useDomain } from '../../utils';
import { format } from 'date-fns';
import TaskHistoryProvider from '../../context/TaskHistoryContext';
import { EngineeringIcon } from '../../components';

export const useServiceList = () => {
  const guest = useAuth((state: any) => state.guest);
  const { data, loading } = usePropertyServicesQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: {
      property_id: guest.property_id
    }
  });

  const computedData = React.useMemo(() => {
    if (!data) {
      return { servicesList: [], groupedServices: [] };
    }
    const parsedTaskList = data.getServices
      ? JSON.parse((data.getServices as any)?.tasklist)
      : [];
    const servicesList = Object.keys(parsedTaskList).map(snakeCase);
    const allServices = flatten(Object.values(parsedTaskList));

    return {
      allServices,
      servicesList,
      groupedServices: parsedTaskList
    };
  }, [data]);
  return { ...computedData, loading };
};

// export const useOpenServiceList = () => {
//   const guest = useAuth((state: any) => state.guest);
//   const { data, loading } = useGetTaskHistoryQuery({
//     variables: {
//       guestID: guest?.guest_id,
//       propertyID: guest?.property_id,
//       roomID: guest?.room_id,
//     },
//     skip: !guest?.guest_id
//   });
//   return { taskHistory: data?.getTaskHistory || [], loading };
// }

interface DropDownProps {
  options: any[];
  sx?: SxProps<Theme>;
  selectedOption: any;
  onClick: (option: any) => void;
  extractId: (option: any) => string;
  extractLabel: (option: any) => string;
}

export const DropDown = (props: DropDownProps) => {
  const { sx, options, selectedOption, extractLabel, extractId, onClick } =
    props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // Vars
  const openMenu = Boolean(anchorEl);

  // Handlers
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ ...(sx ? sx : {}) }}>
      <Button
        sx={{
          py: 1.5,
          px: 2.5,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
        fullWidth
        disableRipple
        disableFocusRipple
        size="large"
        onClick={handleClick}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="body2"
            sx={{ ml: 2, fontWeight: 500, color: 'primary.main' }}
          >
            {extractLabel(selectedOption)}
          </Typography>
        </Box>
        {openMenu ? (
          <KeyboardArrowUpIcon sx={{ color: 'text.primary' }} />
        ) : (
          <KeyboardArrowDownIcon sx={{ color: 'text.primary' }} />
        )}
      </Button>
      <Menu open={openMenu} anchorEl={anchorEl} onClose={handleClose}>
        {options.map(option => (
          <MenuItem
            key={extractId(option)}
            onClick={() => {
              onClick(option);
              handleClose();
            }}
          >
            {extractLabel(option)}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const {
    loading: menuDataLoading,
    Categories,
    Sections,
    data: menuData
  } = useMenuItem();

  // For Promotions
  const [promotionsQuery, { data: promotions}] =
    useGetGuestPromotionsLazyQuery();

  const domainId = useDomain();
  const { data: propertyDetails } =
    usePropertyDetailsQuery({
      variables: {
        domain: domainId as string
      }
    });
  React.useEffect(() => {
    const propertyID = propertyDetails?.getPropertyByDomain?.id;
    if (!propertyID) return;

    promotionsQuery({
      variables: {
        propertyID,
        currDate: format(new Date(), 'yyyy-MM-dd')
      }
    });
  }, [propertyDetails?.getPropertyByDomain?.id, promotionsQuery]);

  const { servicesList, loading: serviceListLoading } = useServiceList();
  const { hotelService, diningType } = useHotelService();
  const { layout } = useInfo();
  const [sortedLayout, setSortedLayout] = React.useState<Layout[]>([]);

  const inRoomDiningList = React.useMemo(() => {
    if (!menuData) return [];
    return Object.keys(
      groupBy(menuData.getGuestEdibles, (data: any) => data.type)
    );
  }, [menuData]);

  const inRoomDiningListSection = React.useMemo(() => {
    if (!menuData) return [];
    return Object.keys(
      groupBy(
        menuData?.getGuestEdibles?.flatMap(item => item?.section),
        section => section
      )
    );
  }, [menuData]);

  React.useMemo(() => {
    const orderedLayout: Layout[] = [];
    if (!layout) {
      const default_layout = [
        {
          cardName: "InfoCard",
          sequence: 0
        },
        {
          cardName: "ServiceAndOrder",
          sequence: 1
        },
        {
          cardName: "QuickServices",
          sequence: 2
        },
        {
          cardName: "InRoomDining",
          sequence: 4
        },
        {
          cardName: "FeedbackAndPromotions",
          sequence: 5
        },
        {
          cardName: "ServiceInformation",
          sequence: 3
        },

      ]

      setSortedLayout(default_layout)

      return ;
    };
    for (const card of layout) {
      if (card) {
        orderedLayout[card?.sequence] = card;
      }
    }
    setSortedLayout(orderedLayout);
  }, [layout]);

  const componentObject: { [key: string]: JSX.Element } = {
    InfoCard: <InfoCard />,
    ServiceAndOrder: <ServiceAndOrder sx={{ mt: 3 }} />,
    Services: (
      <Slider title="Services" loading={serviceListLoading}>
        {servicesList?.map((service, index) => {
          const camelCaseName = startCase(camelCase(service));
          const icon = (SERVICES_ICONS as any)[camelCaseName];
          return (
            <SliderCard
              key={service}
              icon={icon}
              title={camelCaseName}
              index={index}
              link={`services?service=${service}`}
            />
          );
        })}
      </Slider>
    ),
    InRoomDining: (
      <Slider
        title="In Room Dining"
        loading={menuDataLoading}
        onTitleClick={() => navigate('in-room-dining')}
      >
        {diningType === 'Category'
          ? [...(Categories || [])]
          ?.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
          ?.map((category, index) => {
              const icon = (IN_ROOM_CATEGORY_ICONS as any)[
                category?.icon || ''
              ];
              return (
                <SliderCard
                  icon={category?.uploaded_icon || icon}
                  index={index}
                  key={category?.id}
                  gettitle={category?.name || ''}
                  link={`in-room-dining?category=${category?.name}`}
                />
              );
            }) ||
            inRoomDiningList?.map((category, index) => {
              const camelCaseName = startCase(camelCase(category));
              const icon = (IN_ROOM_DINING_ICONS as any)[camelCaseName];
              return (
                <SliderCard
                  icon={icon}
                  index={index}
                  key={category}
                  gettitle={camelCaseName}
                  link={`in-room-dining?category=${category}`}
                />
              );
            })
          : [...(Sections || [])]
              ?.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
              ?.map((section, index) => {
                const icon = (IN_ROOM_CATEGORY_ICONS as any)[
                  section?.icon || ''
                ];
                return (
                  <SliderCard
                    icon={section?.uploaded_icon || icon}
                    index={index}
                    key={section?.id}
                    gettitle={section?.name || ''}
                    link={`in-room-dining?category=${section?.name}`}
                  />
                );
              }) ||
            inRoomDiningListSection?.map((section, index) => {
              const camelCaseName = startCase(camelCase(section));
              const icon = (IN_ROOM_DINING_ICONS as any)[camelCaseName];
              return (
                <SliderCard
                  icon={icon}
                  index={index}
                  key={section}
                  gettitle={camelCaseName}
                  link={`in-room-dining?category=${section}`}
                />
              );
            })}
      </Slider>
    ),
    ServiceInformation: (
      <Slider title="Hotel Services" loading={serviceListLoading}>
        {[...(hotelService || [])]
          ?.sort((a, b) => (a?.sequence || 0) - (b?.sequence || 0))
          ?.map((service, index) => {
            const icon = (SERVICEINFO_ICONS as any)[service?.icon as string] || <EngineeringIcon/>;
            return (
              <SliderCard
                key={service?.name}
                icon={icon}
                title={service.name}
                index={index}
                link={
                  service?.name === 'Restaurants'
                    ? '/dining'
                    : `service-information/${service?.id}`
                }
              />
            );
          })}
      </Slider>
    ),
    FeedbackAndPromotions: (
      <FeedbackAndPromotions
        hidePromotionTile={!Boolean(promotions?.getGuestPromotions?.length)}
        hideFeedback={false}
      />
    ),
    QuickServices: (
      <QuickServices
        sx={{
          mt: 4,
          display: 'contents',
          height: 'auto',
          width: '100%',
          overflowX: 'scroll',
          overflowY: 'hidden',
          whiteSpace: 'nowrap',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      />
    )
  };

  return (
    <Box
      sx={{
        p: 2,
        flex: 1,
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        backgroundColor: 'background.light',
        '&::-webkit-scrollbar': { display: 'none' }
      }}
    >
      <Header />
      <TaskHistoryProvider>
        {sortedLayout?.map(card => componentObject[card?.cardName])}
      </TaskHistoryProvider>
    </Box>
  );
};

export { Dashboard };
